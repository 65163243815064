/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, Menu, ColumnWrapper, Text, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, Title, Image, SeparateLine, SeparateLineWrap } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kamna"} description={"informace o kamnech"}>
        <Column className="pt--10" name={"kamna-oddelovaci-cara"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="--menu pt--20" menu={true} name={"kamna-hlavicka"}>
          
          <Menu style={{"maxWidth":1300,"justify-content":"space-between"}}>
            
            <ColumnWrapper className="menu-logo-box">
              
              <Text className="text-box ff--2 w--600 swpf--uppercase ls--12" style={{"color":"rgb(0,0,0)"}} content={"<span style=\"font-weight: normal;\">DANIEL VLÁHA +420 776 780 996</span>"}>
              </Text>

              <Subtitle className="subtitle-box ff--3 w--400 swpf--uppercase ls--04 mt--0" content={"KAMNÁŘSTVÍ NA ROZKOŠI"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox className="--left pl--40">
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/nabizime"} style={{"maxWidth":125}} target={""} content={"nabízíme"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/cenik"} style={{"maxWidth":125}} target={""} content={"ceník"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/poradna"} style={{"maxWidth":140}} target={""} content={"PORADNA"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/galerie"} style={{"maxWidth":140}} target={""} content={"GALERIE"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/aktuality"} style={{"maxWidth":156}} target={""} content={"aktuality"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/o-nas"} style={{"maxWidth":125}} target={""} content={"O nás"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/kontakt"} style={{"maxWidth":125}} target={""} content={"kontakt"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="--center" name={"lwieo958j4"}>
        </Column>


        <Column name={"kamna_cech"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 fs--14" content={"člen Cechu kamnářů České republiky"}>
              </Title>

              <Image className="mt--08" src={"https://cdn.swbpg.com/o/17440/5c02d8768d2342198c276b8957ed27a4.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":60}} srcSet={""}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"kamna-oddelovac-1"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--menu pb--0 pt--0" menu={true} name={"kamna-rozcestnik"} style={{"left":"0","zIndex":"2","overflow":"visible","position":"absolute"}}>
          
          <Menu >
            
            <MenuButton >
            </MenuButton>

            <MenuWrapBox className="--style5 --center">
              
              <MenuButtonClose >
              </MenuButtonClose>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/kamna"} target={""} content={"KAMNA"}>
              </Button>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/sporaky"} target={""} content={"SPORÁKY"}>
              </Button>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/kombinovana_topidla"} target={""} content={"KOMBINOVANÁ TOPIDLA"}>
              </Button>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/krby"} target={""} content={"KRBY"}>
              </Button>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/kominy"} target={""} content={"KOMÍNY"}>
              </Button>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/speciality"} target={""} content={"SPECIALITY"}>
              </Button>

            </MenuWrapBox>

          </Menu>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"fosdccfl4it"}>
        </SeparateLine>


        <SeparateLine className="pb--10" name={"kamna-oddelovac-2"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="css-n58pvk bg--center --parallax pb--40 pt--40" name={"kamna-vstup"} layout={"l1"} parallax={true} css={css`
      
    background-image: linear-gradient(rgba(17,20,28,0.15), rgba(17,20,28,0.15)), url(https://cdn.swbpg.com/t/17440/0f53d109db124e7ca3600965a4927ef5_e=150x143x2159x1589__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(17,20,28,0.15), rgba(17,20,28,0.15)), url(https://cdn.swbpg.com/t/17440/0f53d109db124e7ca3600965a4927ef5_e=150x143x2159x1589__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(17,20,28,0.15), rgba(17,20,28,0.15)), url(https://cdn.swbpg.com/t/17440/0f53d109db124e7ca3600965a4927ef5_e=150x143x2159x1589__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(17,20,28,0.15), rgba(17,20,28,0.15)), url(https://cdn.swbpg.com/t/17440/0f53d109db124e7ca3600965a4927ef5_e=150x143x2159x1589__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(17,20,28,0.15), rgba(17,20,28,0.15)), url(https://cdn.swbpg.com/t/17440/0f53d109db124e7ca3600965a4927ef5_e=150x143x2159x1589__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(17,20,28,0.15), rgba(17,20,28,0.15)), url(https://cdn.swbpg.com/t/17440/0f53d109db124e7ca3600965a4927ef5_e=150x143x2159x1589__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(17,20,28,0.15), rgba(17,20,28,0.15)), url(https://cdn.swbpg.com/t/17440/0f53d109db124e7ca3600965a4927ef5_e=150x143x2159x1589__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(17,20,28,0.15), rgba(17,20,28,0.15)), url(https://cdn.swbpg.com/t/17440/0f53d109db124e7ca3600965a4927ef5_e=150x143x2159x1589__s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":600,"backgroundColor":"rgba(255,255,255,1)"}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":550}}>
              
              <Title className="title-box fs--102" content={"KAMNA"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: rgb(0, 0, 0);\">Ono pověstné srdce domova vždy tvořila a tvoří kamna. <br>Ať už kachlová sálavá, akumulační teplovodní nebo třeba omítaná hliněným štukem budou vždy dominantou každé místnosti. <br>Vychutnejte si i vy teplo jejich hřejivé náruče.</span>"}>
              </Text>

              <Button className="btn-box btn-box--cbtn2 btn-box--pbtn2 btn-box--cColor1 btn-box--filling2 mb--10 mt--20" href={"#kamna-popis"} content={"CHCI TOPIT!"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"kamna-oddelovac-3"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--10 pt--10" name={"kamna-popis"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Co jsou kamna a <br>k čemu je využiji?"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--justify" style={{"maxWidth":334}} content={"&nbsp;Stručně řečeno, je to topidlo, které používáme pro vytápění prostor. Jak snadné, že?<br><br>&nbsp;Moderní kamna toho ale umí podstatně víc, než jen vytopit místnost, ve které se nacházejí. Nová bezroštová topeniště maximálně využívají potenciál dřevní hmoty, spalují ji totiž kolem 800-900˚C a to s minimem emisí a účinností atakující hranici přes 80%. Pomocí šamotových tahových systémů se pak ukládá do akumulační hmoty kamen energie v podobě tepla, které se bude do prostoru uvolňovat ještě spousty hodin po vyhasnutí ohně v topeništi. V praxi to znamená, že vytápíte, aniž byste topili. Vtipné))).\n<br>&nbsp;Uzavřený hypokaustní systém umí teplo dostat do okolních místností i do patra bez potřeby elektrické energie či vody. Vše funguje na principech termiky a&nbsp; samotíže.<br>Případně kamna pomocí teplovodního výměníku či absorberu ohřátou vodu distribuují do otopné soustavy celého domu, ukládají ji do akumulační nádrže, ohřívají ji jako užitkovou vodu v bojleru...&nbsp; &nbsp;Možná kombinace hypokaustního a teplovodního systému je dnes běžnou praxí.&nbsp;<br>&nbsp;Kamna se vždy dimenzují dle potřeb a tepelné ztráty objektu, proto byste měli místnosti vždy bezpečně vytopit, ale nepřetápět.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--justify" style={{"maxWidth":334}} content={"&nbsp;To bývá běžným nešvarem krbových kamen či levnějších konvekčních systémů. U stropu k nevydržení, u podlahy zima.<br>To se vám u sálavých kamen nestane.<br>Sálavé teplo z akumulačních staveb je zdravé&nbsp; a pocitově nemá ve vytápění konkurenci.<br><br>A protože se topidla vyrábějí na zakázku podle přání zákazníka, co do vzhledu a povrchových úprav se nijak neomezují, Setkáme se tak s různými tvary, vzhledu tradičního, moderního či historizujícího. Nejen s klasickými keramickými kachlemi, ale i velkoplošnou keramikou nebo přírodním kamenem. Mohou být omítaná bíle nebo v barvách, oblíbené jsou i hliněné povrchy... Kamna se často kombinují s dalším u nás velmi oblíbeným topidlem - kachlovým sporákem - a vytváří tak ideální tandem.&nbsp; <br>&nbsp;Pokud to tedy s vytápěním myslíte opravdu vážně a hledáte ekologické topidlo vzhledu, jaký jste si vysnili a které Vám bude dávat sálavé teplo po dlouhé roky, jsou pro vás akumulační kamna ideální volbou!"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--filling2 fs--14 mt--60" href={"/poradna"} target={null} content={"CHCI ZNÁT VÍC!"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"kamna-oddelovac-4"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center pb--10 pt--10" name={"kamna-galerie"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"GALERIE"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--10 pt--10" name={"kamna-galerie-rozcestnik"} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="mb--40">
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/17440/6644a1c1f58b44a481dd3bec6be43421_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/17440/6644a1c1f58b44a481dd3bec6be43421_s=350x_.png 350w, https://cdn.swbpg.com/t/17440/6644a1c1f58b44a481dd3bec6be43421_s=660x_.png 660w, https://cdn.swbpg.com/t/17440/6644a1c1f58b44a481dd3bec6be43421_s=860x_.png 860w"} position={{"x":"-63.46153846153849%","y":"0%"}}>
              </Image>

              <Text className="text-box" content={"3D projekce je tu proto, abyste měli představu, jak to plánovanému topidlu bude slušet u vás doma.<br>Podívejte se, jak to vypadá.<br>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--filling2 mt--16" href={"/galerie#galerie-3d-nadpis"} target={null} content={"3D PROJEKCE"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="mb--40">
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/17440/47f1651291524e46a6febf747cc53513_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/17440/47f1651291524e46a6febf747cc53513_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17440/47f1651291524e46a6febf747cc53513_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17440/47f1651291524e46a6febf747cc53513_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17440/47f1651291524e46a6febf747cc53513_s=1400x_.jpg 1400w"} position={{"x":"0%","y":"-6.230769230769231%"}}>
              </Image>

              <Text className="text-box" content={"Hledáte inspiraci nebo jste jen zvědaví, co všechno jsme stavěli a co nám vyrostlo pod rukama? Nakoukněte do naší galerie realizovaných projektů."}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--filling2 mt--16" innerClassName="" href={"/galerie#galerie-kamna-nadpis"} target={null} content={"REALIZACE KAMEN"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/17440/2845f52089c14924abdc4d7adcf1524d_s=660x_.JPG"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/17440/2845f52089c14924abdc4d7adcf1524d_s=350x_.JPG 350w, https://cdn.swbpg.com/t/17440/2845f52089c14924abdc4d7adcf1524d_s=660x_.JPG 660w"} position={{"x":"-30.384615384615394%","y":"-5.230769230769231%"}}>
              </Image>

              <Text className="text-box" content={"Líbí se vám některá kamna nebo snad sporák? <br>Rádi byste měli něco podobného i u vás doma?<br>Svěřte se svému kamnáři!<br>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--filling2 mt--16" href={"/kontakt"} target={null} content={"MŮJ KAMNÁŘ"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"kamna-oddelovac-5"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="css-xn7sl8 --parallax pb--60 pt--60" name={"kamna-konzultace"} layout={"l1"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=350x_.JPG);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=660x_.JPG);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=860x_.JPG);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=1400x_.JPG);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=2000x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=660x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=1400x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=3000x_.JPG);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 pb--20 pl--40 pr--40 pt--20" style={{"maxWidth":520,"backgroundColor":"rgba(255,255,255,1)"}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":520}}>
              
              <Title className="title-box" content={"KAMNÁŘSTVÍ <br>NA ROZKOŠI"}>
              </Title>

              <Text className="text-box" content={"Rodinné kamnářství na břehu vodní nádrže Rozkoš. Přijďte si k nám pro návrh šitý vám na míru."}>
              </Text>

              <Button className="btn-box btn-box--filling2 mt--16" href={"/kontakt"} target={null} content={"KONZULTACE"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"kamna-oddelovac-6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--menu pb--08 pt--20" menu={true} name={"kamna-zapati"}>
          
          <Menu style={{"justify-content":"space-between"}}>
            
            <ColumnWrapper className="menu-logo-box">
              
              <Text className="text-box ff--2 w--600 swpf--uppercase ls--12" style={{"color":"rgb(0,0,0)"}} content={"<span style=\"font-weight: normal;\">Daniel vláha +420 776 780 996</span>"}>
              </Text>

              <Subtitle className="subtitle-box ff--3 w--400 swpf--uppercase ls--04 mt--0" content={"kamnářství na rozkoši"}>
              </Subtitle>

              <Subtitle className="subtitle-box ff--3 fs--12 mt--06" content={"člen Cechu kamnářů České republiky"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton className="--hvr2 --filling2">
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--style2 fs--16 w--600 btn-box--invert lh--1" href={"/nabizime"} target={null} content={"NABÍZÍME"}>
                </Button>

                <Button className="btn-box btn-box--style2 fs--16 w--600 btn-box--invert lh--1" href={"/kontakt"} target={null} content={"KONTAKT"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="--center" name={"kamna-mesta"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Text className="text-box text-box--center ff--3 fs--14 swpf--uppercase" content={"NÁCHOD - ČESKÁ SKALICE - NOVÉ MĚSTO NAD METUJÍ - ČERVENÝ KOSTELEC - BROUMOV - TRUTNOV - DOBRUŠKA - OPOČNO - RYCHNOV NAD KNĚŽNOU - POLICE NAD METUJÍ"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pt--10" name={"kamna-oddelovac-7"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="pt--0" style={{"paddingBottom":2,"backgroundColor":"var(--color-variable-1)"}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"kamna-paticka"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box btn-box--pbtn2 btn-box--filling2 fs--11" href={"/zasady-ochrany-osobnich-udaju"} target={null} content={"ZÁSADY OCHRANY OSOBNÍCH ÚDAJŮ"}>
              </Button>

              <Text className="text-box ff--3 mt--06" style={{"color":"rgb(0, 0, 0)"}} content={"Přečtěte si podrobné informace o našich Zásadách ochrany osobních údajů před dalším užíváním těchto stránek.<br>Veškeré zde zveřejněné informace a fotografie jsou majetkem Kamnářství na Rozkoši. Jejich šíření je bez souhlasu majitele zakázáno! Děkujeme, že respektujete autorská práva.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}